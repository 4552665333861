import * as React from "react";

const CmtClientDetail = props => {
  const { client } = props;
  if (!{ client }) {
    return <div></div>;
  }
  return (
    <img
      src={require("../assets/images/" + client.logo)}
      alt={client.name}
      className="logoType"
    />
  );
};

export default CmtClientDetail;

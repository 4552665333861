export default function()
{
    return[
        {name:'React', path:'react.png', id:1},
        {name:'C#', path:'csharp.png', id:2},
        {name:'AWS',  path:'aws.png',id:3},
        {name:'Java', path:'java.png',id:4},    
        {name:'PowerBI', path:'powerbi.png',id:5},
        {name:'TensorFlow',path:'tensorflow.svg', id:6},
        {name:'Python', path:'python.png', id:7},
        {name:'Android', path:'android.png',id:8}
    ]
}       

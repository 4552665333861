import * as React from "react";

export default class CmtAdress extends React.Component {
  cmtAdresses() {
    return (
      <div className="position-adress">
        <div className="text-center">
          <div>
            <i className="fa fa-phone fa-3x fa-color fa-content" />
            <p className="blocnote">+33(0)6 76 74 31 54</p>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return this.cmtAdresses();
  }
}

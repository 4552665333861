import * as React from "react";
import Particles from "react-particles-js";
import CmtComposantDefaut from "./CmtComposantDefaut";
export default class Banner extends React.Component {
  render() {
    return (
      <CmtComposantDefaut couleur="darksky">
        <Particles
          className="container-particle"
          canvasClassName="canvas-particle"
          params={{
              particles: {
              number: {
                value: 50,
                density: {
                  enable: true,
                  value_area: 100
                }
              },
              size: {
                value: 3,
                random: true
              },
              line_linked: {
                enable: true,
                distance: 50,
                opacity: 0.4,
                width: 1
              },
              move: {
                speed: 1
              },
              opacity: {
                anim: {
                  enable: true,
                  opacity_min: 0.05,
                  speed: 2,
                  sync: false
                },
                value: 0.4
              }
            },
            retina_detect: false,
            interactivity: {
              detect_on: "canvas",
              events: {
                onhover: {
                  enable: true,
                  mode: "grab"
                },
                onclick: {
                  enable: true,
                  mode: "push"
                },
                resize: true
              },
              modes: {
                grab: {
                  distance: 80,
                  line_linked: {
                    opacity: 1
                  }
                }
              }
            }
          }}
        />
        <div className="banner">
          <div className=" col-md-7 white-text-container">
            <div className="fadeInUp">
              <h1 className="text-left">
                D&#233;veloppement int&#233;gr&#233;
              </h1>
              <h2 className="text-left">aux solutions logicielles</h2>
              <h4 className="text-left">
                Prenez une longueur d'avance sur vos concurrents en utilisant
                les derni&#232;res technologies adapt&#233;es &#224; votre
                c&#339;ur de m&#233;tier
              </h4>
            </div>
          </div>

          <div className="col-md-5 code-container white-text">
            <h4 className="cl-code">
              <span>&#47;&#47; solution workflow</span>
            </h4>
            <h4 className="cl-code">
              <span>if (technology_gap() === true) &#123;</span>{" "}
            </h4>
            <h4 className="cl-code">
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>technology_gap().stop();</span>{" "}
            </h4>
            <h4 className="cl-code">
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span>ezalgo();</span>{" "}
            </h4>
            <h4 className="cl-code">
              <span>&#125;</span>{" "}
            </h4>
          </div>
        </div>
      </CmtComposantDefaut>
    );
  }
}

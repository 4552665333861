import * as React from 'react'
import {connect} from 'react-redux'
import CmtClientDetail from "./CmtClientDetail"

class CmtClients extends React.Component {
 render (){
    return(
        <div className="section-container" style={{backgroundColor:"#5a9bff59"}} id="ents">
            <div className="container">
                <div className="row" >
                    <div className="col-xs-12">
                        <h3>Ils nous font confiance</h3>
                    </div>
                </div>
                <div className="row">
                <div className="col-xs-12 container-inline">
                    {
                        this.props.myClients.map(client => {
                            return (
                                <CmtClientDetail client={client} key={client.id}></CmtClientDetail>
                            )
                        })
                    }
                  </div>
                </div>
            </div>
     
     </div>
    );
 }
}

function mapStateToProps(state){
    return{
       myClients: state.clients
    }
}   

export default connect(mapStateToProps)(CmtClients)
import * as React from "react";
import { connect } from "react-redux";
import SectionShape from "./ComponentChildren";
import CmtSkillDetail from "./CmtSkillDetail";
class CmtSkills extends React.Component {
  render() {
    return (
      <SectionShape couleur="greylight" Id="skills">
        <div className="row">
          <div className="pager">
            {this.props.mySkills.map(skill => {
              return (
                <CmtSkillDetail skill={skill} key={skill.id}></CmtSkillDetail>
              );
            })}
          </div>
        </div>
      </SectionShape>
    );
  }
}

function mapStateToProps(state) {
  return {
    mySkills: state.skills
  };
}

export default connect(mapStateToProps)(CmtSkills);

import React from "react";
import { Email,Item, A } from "react-html-email";
export default function InlineLink({ name, email, subject, children }) {
  const txtBold = { // Saut de ligne
    fontWeight: 'bold',
  }
  return (
    <Email title="Notification de Contact sur Ezalgo.com">
      <Item><span>&nbsp;</span></Item>
      <Item><span>Bonjour,</span></Item>
      <Item><span>&nbsp;</span></Item>
      <Item><span>Vous avez re&#231;u un contact depuis Ezalgo.com</span></Item>
      <Item><span>&nbsp;</span></Item>
      <Item><span  {...txtBold}>Nom:</span> {name}</Item>
      <Item><span  {...txtBold}>Email:</span> {email}</Item>
      <Item><span  {...txtBold}>Sujet:</span> {subject}</Item>
      <Item><span  {...txtBold}>Message:</span> {children}</Item>
      <Item><span>&nbsp;</span></Item>
      <Item>
        Envoy&#233; depuis le site web Ezalgo:
        <A style={{ paddingLeft: 20 }} href="https://www.ezalgo.com">
          https://www.ezalgo.com
        </A>
      </Item>
    </Email>
  );
}

import * as React from "react";
import logo from "../assets/ezalgo_logo.png";

class CmtFooter extends React.Component {
  render() {
    return (
      <footer>
        <div className="section-container footer-container bluesky" id="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
              <div>
              <img
                  src={logo}
                  className="footer-logo"
                  alt="La soci&#233;t&#233; Ezalgo"
                />
                </div>
                <div>
                <a
                  href="https://www.societe.com/societe/ezalgo-842323214.html"
                  rel="noopener"
                  target="new"
                  title="own society"
                  className="fa-color"
                >
                  <i
                    className="fa fa-bookmark fa-2x"
                    aria-hidden="true"
                  ></i>
                </a>
                </div>
              </div>
              <div className="col-md-4">
                <h4>Rejoignez-nous &#33;</h4>
                <p>
                  <a
                    href="https://www.linkedin.com/company/ezalgo/about/"
                    target="new"
                    rel="noopener"
                    className="social-round-icon white-round-icon fa-icon fa-color"
                    title="social society"
                  >
                    <i
                      className="fa fa-linkedin"
                      aria-hidden="true"
                    ></i>
                  </a>
                </p>
                <p>
                  <small>Copyright &#169;2020 EZALGO. Tout droits réservés.</small>
                </p>
              </div>
              <div className="col-md-4">
                <div>
                  <h4>Recrutement</h4>
                </div>
                <div>
                  <p>
                    Pour postuler &#224; un emploi avec notre &#233;quipe,
                    n'h&#233;sitez pas &#224; nous envoyer votre lien de profil
                    Linkedin
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default CmtFooter;

import * as React from "react";
import CmtLayoutBase from "./CmtLayoutBase";
import Resume from "./CmtResume";
import Skills from "./CmtSkills";
import Clients from "./CmtClients";
import Kpis from "./CmtKpis";
import Contact from "./CmtContactIn";
import Technos from "./CmtTechnophyte";

class CmtPageAccueil extends React.Component {
  pageAccueil() {
    return (
      <div className="container-page-accueil">
        <Resume />
        <Technos/>
        <Skills />
        <Clients />     
        <Contact/>
        <Kpis />
      </div>
    );
  }
  render() {
    return (
      <CmtLayoutBase
        titre="Ezalgo - Prototypage rapide d'applications"
        contenuPage={this.pageAccueil()}
      />
    );
  }
}

export default CmtPageAccueil;

import { combineReducers } from "redux";
import RdrSkills from "./RdrSkills";
import RdrKpis from "./RdrKpis";
import RdrClients from "./RdrClients";
import RdrTeks from "./RdrTechnos";

const rootReducer = combineReducers({
  skills: RdrSkills,
  clients: RdrClients,
  kpis: RdrKpis,
  technos: RdrTeks
});

export default rootReducer;

import * as React from "react";
import CmtComposantDefaut from "./CmtComposantDefaut";
import CmtLayoutBase from "./CmtLayoutBase";
import { withRouter } from "react-router-dom";
const qs = require("query-string");

class CmtFormRetour extends React.Component {
  pageFormRetour() {
    let parametres = qs.parse(this.props.location.search);
    return (
      <CmtComposantDefaut>
        <div className="row">
          <div className="col-xs-12">
            <h3>Contact envoyé</h3>
            <br />
            <p className="text-left">
              Merci&#44; <b>{parametres.name}</b>&#44; de votre demande d&#39;informations,
              nous ferons suite &#224; cet &#233;change dans les plus bref d&#233;lai&#46;
            </p>
          </div>
        </div>
      </CmtComposantDefaut>
    );
  }

  render() {
    return (
      <CmtLayoutBase
        titre="Ezalgo - Prototypage rapide d'applications - Demande envoyée"
        contenuPage={this.pageFormRetour()}
      />
    );
  }
}
export default withRouter(CmtFormRetour);

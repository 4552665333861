import * as React from "react";

export default class CmtMap extends React.Component {
  render() {
    return (
      <div className="col-md-6 col-lg-6 container-map">
        <iframe
          id="geomap"
          title="geoloc"
           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d92456.81931067242!2d1.3628017807448778!3d43.60080288441364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aebb6fec7552ff%3A0x406f69c2f411030!2sToulouse!5e0!3m2!1sfr!2sfr!4v1579287128353!5m2!1sfr!2sfr"
        ></iframe>
      </div>
    );
  }
}

import * as React from "react";
import VizSensor from 'react-visibility-sensor';

class CmtKpiDetail extends React.Component {
  
  constructor(props) {
    super(props);
    if(this.props.kpi.type === "entier") {
      var incrementChoisie = 1;
      if(this.props.dureeCounter / this.props.kpi.content < 10) { // setInterval met tous les intervalles <10 à 10
        incrementChoisie = 10;
      }
      this.state = {
        counterDejaLance: false,
        valeurDebut: 1,
        increment: incrementChoisie,
        valeurFin: this.props.kpi.content,
        intervalle: this.props.dureeCounter*incrementChoisie / this.props.kpi.content // dureeCounter ms/nombre incrément = nbre interval entre increments

      }
    } else if(this.props.kpi.type === "date") {
      // Difference entre 14/08/2018 et aujourd'hui
      let unJour = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      let premiereDate = new Date(2018, 8, 14, 12, 0, 0, 0); // 14/09/2018
      let secondeDate = new Date();      
      let diffJours = Math.round(Math.abs((premiereDate.getTime() - secondeDate.getTime()) / (unJour)));

      const incrementChoisie = 10;
      this.state = {
        counterDejaLance: false,
        valeurDebut: 1,
        increment: incrementChoisie,
        valeurFin: diffJours, // defaut 420
        intervalle: this.props.dureeCounter*incrementChoisie / diffJours // dureeCounter ms/nombre incrément = nbre interval entre increments
      }
    }
    this.counterEstLanceInitIntervalFunction = this.counterEstLanceInitIntervalFunction.bind(this);
  }

  componentDidMount() {}
  // exécutée une seule fois
  counterEstLanceInitIntervalFunction() {
    this.maFunctionInterval = setInterval(() => {
      const { valeurDebut, valeurFin, increment} = this.state

      if (valeurDebut < valeurFin) {
          this.setState(({ valeurDebut }) => ({
            valeurDebut: valeurDebut + increment,
            counterDejaLance: true
          }))
      } else {
        this.setState(({ valeurDebut }) => ({
          valeurDebut: valeurFin
        }))
        clearInterval(this.maFunctionInterval)
      }
    }, this.state.intervalle)
  }

  componentDidUpdate(prevProps) { }

  componentWillUnmount() {
    if(this.state.counterDejaLance) {
      clearInterval(this.maFunctionInterval)
    }
  }

  render() {
    if (! this.props.kpi ) {
      return <div>Ca crash !!! </div>;
    }
    return (
      <VizSensor
              onChange={(isVisible) => {
                if(isVisible && (!this.state.counterDejaLance)) {
                  this.counterEstLanceInitIntervalFunction();
                }
              }}>
        <div className="col-md-4">
          <div className="text-center">
            <div className="fa-container">
              <i
                className={"fa " + this.props.kpi.fa + " fa-2x fa-white"}
                aria-hidden="true"
              ></i>
            </div>
            <h3>{this.state.valeurDebut}</h3>
            <div>
              <p>{this.props.kpi.name}</p>
            </div>
          </div>
        </div>
      </VizSensor>
    );
  }
}

export default CmtKpiDetail;

import React from "react";
import { Email, Item, A } from "react-html-email";
export default function InlineLink({ name, email, subject, children }) {
  const txtBold = { // Saut de ligne
    fontWeight: 'bold',
  }
  return (
    <Email title="Notification de votre contact envoyé à Ezalgo.com">
      <Item><span>&nbsp;</span></Item>
      <Item>Bonjour <span {...txtBold}>{name},</span></Item>
      <Item><span>&nbsp;</span></Item>
      <Item>
        <span >
        Votre demande d&#39;informations est prise en compte par notre &#233;quipe&#44; nous ferons suite &#224; cet &#233;change dans les plus bref d&#233;lais&#46;
        </span>
      </Item>
      <Item><span>&nbsp;</span></Item>
      <Item><span>L&#39;&#233;quipe Ezalgo vous remercie de votre visite&#46;</span></Item>
      <Item><span>&nbsp;</span></Item>
      <Item>
        <A href="https://www.ezalgo.com">
          https://www.ezalgo.com
        </A>
      </Item>
    </Email>
  );
}

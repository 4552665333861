import * as React from "react";
import CmtComposantDefaut from "./CmtComposantDefaut";
import CmtForm from "./CmtForm";
import Title from "./Title";
const urls = require("../urls");

export default class CmtContact extends React.Component {
  state = {
    data: null
  };

  componentDidMount() {
    // Call our fetch function below once the component mounts
    /*  Cette requete était utilisée pour tester le bon fonctionnement du serveur node
    this.callBackendAPI()
      .then(res => this.setState({ data: res.express }))
      .catch(err => console.log(err));
      */
  }
  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch(urls.NODE_BACKEND);
    //const response = await fetch('/express_backend/'); // localhost
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  pageContact() {
    return (
      <CmtComposantDefaut couleur="whitesky" Id="contactIn">
        <div id="div_above_contact_form" className="row container-center">
          <Title title="Nous contacter" />
          <div className="col-md-12 container-form">
            <CmtForm></CmtForm>
          </div>
        </div>
      </CmtComposantDefaut>
    );
  }
  render() {
    return this.pageContact();
  }
}

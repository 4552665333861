import * as React from "react";
import { connect } from "react-redux";
import CmtComposantDefaut from "./CmtComposantDefaut";
import CmtKpiDetail from "./CmtKpiDetail";

class CmtKpis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estVisible: false,
      counterDejaLance: false,
    }
  }
  render() {
    return (
      <CmtComposantDefaut couleur="darksky" Id="kpis">
        <div className="row white-text-container">
          <div className="col-md-12 counter">
            {this.props.myKpis.map(kpi => {
              return <CmtKpiDetail key={kpi.id} kpi={kpi} 
                          dureeCounter={1000}
                      ></CmtKpiDetail>;
            })}
          </div>
        </div>
      </CmtComposantDefaut>
    );
  }
}

function mapStateToProps(state) {
  return {
    myKpis: state.kpis
  };
}

export default connect(mapStateToProps)(CmtKpis);

import * as React from 'react'

const Title = ({title}) =>{
    return(
        <div className="title">
            <div className="divider "></div>
            <div>
             <h3 className="sub-title">{title}</h3>
            </div>
            
        </div>
    );
}

export default Title;
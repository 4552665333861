import * as React from "react";
import { connect } from "react-redux";
import Section from "./CmtComposantDefaut";

class CmtTechnophyte extends React.Component {
  render() {
    return (
      <Section couleur="whitesky">
        <div className="row">
          <div className="col-md-12 container-block">
            {this.props.myTechnos.map(teck => {
              return (
                <img
                  src={require("../assets/technos/" + teck.path)}
                  alt={teck.name}
                  className="padding-one"
                  title={teck.name}
               key={teck.id}></img>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }
}

function mapStateToProps(state) {
  return {
    myTechnos: state.technos
  };
}

export default connect(mapStateToProps)(CmtTechnophyte);

import * as React from "react";

class CmtComponentChildren extends React.Component {
  render() {
    return (
      <div className={this.props.couleur} id={this.props.Id}>
        <div className="section-shape">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="container">{this.props.children}</div>
      </div>
    );
  }
}

export default CmtComponentChildren;

import React from "react";

const CmtSkillDetail = props => {
  const { skill } = props;

  if (!{ skill }) {
    return <div>Ca crach !!! </div>;
  }
  return (
    <div className="col-md-4">
      <div className="container-skill-box text-center">
      <div className="padding-one">
         <img src={require("../assets/images/"+skill.img)} alt={skill.name}/>
        </div>
        <div >
          <h4>{skill.name}</h4>
        </div>
       
        <div className="padding-one">
          <span>{skill.contenu}</span>
        </div>
      </div>
    </div>
  );
};

export default CmtSkillDetail;

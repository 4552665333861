import * as React from "react";
import CmtComposantDefaut from "./CmtComposantDefaut";
import Title from "./Title";

export default class CmtResume extends React.Component {
  render() {
    return (
      <CmtComposantDefaut couleur="whitesky" Id="home">
        <div className="row container-center">
          <Title title="Ce que nous faisons" />
          <div className="container-resume col-md-12">
            <h2 className="text-left">
              Consulting <span className="surlign text-scale">&amp; </span>
              <span className="H-color">D&#233;veloppement logiciel</span>
            </h2>
          </div>
          <div className="col-md-12">
            <p className="text-left">
            Jeune soci&#233;t&#233; Toulousaine, cr&#233;&#233;e en 2018, Ezalgo est une entreprise de services num&#233;riques. Notre savoir-faire est propuls&#233; 
            par une expertise forte de plusieurs ann&#233;es d'exp&#233;riences auprès de nos clients&#46;
            </p>
            <p className="text-left">
            Nous num&#233;risons votre strat&#233;gie m&#233;tier en phase avec les dernières technologies du march&#233;&#46;
            Nous effectuons cette transition digitale par l'int&#233;gration des solutions r&#233;alis&#233;es dans votre SI&#46;
            </p>
            <p className="text-left">
            Les experts m&#233;tiers de notre &#233;quipe sont à l'&#233;coute de vos besoins&#46;
            </p>
          </div>
        </div>
      </CmtComposantDefaut>
    );
  }
}

export default function() {
  return [
    {
      name: "Technologie de pointe",
      img: "double_check.png",
      chevron: "fa-globe",
      contenu:
        "Maitriser les technologies les plus récentes pour vous permettre de gérer, construire et faire évoluer les contextes de veilles, de DevOps.",
      id: 4
    },
    {
      name: "DevOps & Multiplateforme",
      img: "devices.png",
      chevron: "fa-terminal",
      contenu:
        "Utiliser des technologies modernes et progressives. Nous réalisons la création d'applications multi-plateformes sécurisées, web et mobile. ",
      id: 2
    },
    {
      name: "Cycles courts",
      img: "restore.png",
      chevron: "fa-history",
      contenu:
        "S'engager dans une transformation digitale grâce à des résultats rapides et visibles. Nous nous adaptons pour rationaliser les développements courts.",
      id: 3
    },
    {
      name: "Data Intelligence",
      img: "charts.png",
      chevron: "fa-line-chart",
      contenu:
        "Faire émerger votre métier en réalisant des modèles de données exploitables et évolutifs sur le long terme. Agréger, analyser et visualiser vos données en provenance de sources multiples.",
      id: 1
    },
    {
      name: "AWS Cloud Computing",
      img: "cloud.png",
      chevron: "fa-cloud",
      contenu:
        "Mettre en oeuvre les traitements adéquats. Vos applications accessibles partout avec des coûts maitrisés. Gérez parfaitement leurs scalabilités et profitez de la plateforme Amazon.",
      id: 5
    },
    {
      name: "Web Scraping",
      img: "settings.png",
      chevron: "fa-angle-double-up",
      contenu:
        "Vous avez besoin de stimuler les données de votre business ? Nous réalisons aussi des solutions complètes et dédiées de scraping. Venez les découvrir!",
      id: 6
    }
  ];
}

import * as React from "react";
import CmtHeader from "./CmtHeader";
import CmtFooter from "./CmtFooter";
import CookieConsent from "react-cookie-consent";
import Banner from "./CmtBanner";

class CmtLayoutBase extends React.Component {

  render() {
    return (
      <div className="container-layout-base">
        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="ezalgoCookiesConsentement"
          containerClasses=""
          buttonClasses="cookie-button"
          declineButtonClasses=""
          contentClasses=""
          expires={150}
        >
          Ce site web utilise des cookies. {" "}
                    <span style={{ fontSize: "12px" }}>
                        En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies ou autres traceurs par Ezalgo afin d'améliorer l'expérience utilisateur.
                    </span>
        </CookieConsent>
        <CmtHeader />
        <Banner/>
        {this.props.contenuPage}
        <CmtFooter />
      </div>
    );
  }
}

export default CmtLayoutBase;

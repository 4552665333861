import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import CmtPageAccueil from "./components/CmtPageAccueil";
import CmtContact from "./components/CmtContact";
import CmtFormRetour from "./components/CmtFormRetour";
import history from './history';

function App() {
  return (
      <Router history={history}>
        <Switch>
            <Route exact path="/">
                <CmtPageAccueil />
            </Route>
          <Route path="/contact">
            <CmtContact />
          </Route>
            <Route path="/merci">
                <CmtFormRetour />
            </Route>
        </Switch>
      </Router>
  );
}
export default App;

import * as React from "react";

class CmtComposantDefaut extends React.Component {
    render() {
        return (
            <div className={"section-container " +this.props.couleur} id={this.props.Id}>
                <div className="container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default CmtComposantDefaut;

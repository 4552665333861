import * as React from "react";
import logo_noir from "../assets/ezalgo_logo.png";

class CmtHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleNav = this.handleNav.bind(this);
  }
   componentDidMount() {
    this.setState({
      visible: !this.state.visible
    });
  }
 
  handleNav(e) {
    this.toggleMenu();
    this.setState({
      visible: !this.state.visible
    });
    e.preventDefault();
    e.stopPropagation();
  }

  toggleMenu= () => {
    let x = document.getElementById("nav-toggle");
    if (this.state.visible) {
      x.className = "navbar-toggle uarr";
    } else {
      x.className = "navbar-toggle uarr collapsed";
    }

    this.handleDisplay();
  }

  handleDisplay() {
    let y = document.getElementById("navbar-collapse-uarr");
    if (this.state.visible) {
      y.className = "navbar-collapse collapse in";
    } else {
      y.className = "navbar-collapse collapse";
    }
  }

  handleClick = () => {
    window.location.href = "/#contactIn";
  };

  render() {
    return (
      <header>
        <nav className="navbar-fixed-top navbar-default bluesky">
          <div className="container">
            <div className="info-society">
              <span>
                <i className="fa fa-user-circle"></i> Toulouse, France
              </span>
              <span>
                <i className="fa fa-phone" /> 06 76 74 31 54
              </span>
              <span>
                <i className="fa fa-envelope" />
                <a href="mailto:contact@ezalgo.com"> contact@ezalgo.com</a>
              </span>
            </div>
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle uarr collapsed"
                data-toggle="collapse"
                data-target="#navbar-collapse-uarr"
                name="btnNavigation"
                id="nav-toggle"
                aria-expanded={this.state.visible}
                onClick={this.handleNav}
              >
                <span className="sr-only">Navigation</span>
                <i className="fa fa-th-large fa-lg fa-container"></i>
              </button>
              <a className="navbar-brand" href="/" title="Accueil Ezalgo">
                <img
                  src={logo_noir}
                  className="navbar-logo-img"
                  alt="Soci&#233;t&#233; Ezalgo"
                />
              </a>
            </div>
            <div
              className="collapse navbar-collapse"
              id="navbar-collapse-uarr"
              aria-expanded={this.state.visible}
            >
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="/" title="Accueil">
                    Accueil
                  </a>
                </li>
                <li>
                  <a href="/#skills" title="Savoir-faire Ezalgo">
                    Savoir-Faire
                  </a>
                </li>
                <li>
                  <button
                    onClick={this.handleClick}
                    id="linkContact"
                    className="contact-btn btn-secondary"
                    name="btnContact"
                  >
                    Contact
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default CmtHeader;

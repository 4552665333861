import * as React from "react";
import axios from "axios";
import MonEmail from "./CmtEmail";
import UserEmail from "./CmtUserEmail";
import { renderEmail } from "react-html-email";
import ReCAPTCHA from "react-google-recaptcha";
const urls = require('../urls');

export default class FormContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      checkPolicy: false
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeCheckPolicy = this.handleChangeCheckPolicy.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeReCaptcha = this.onChangeReCaptcha.bind(this);
    this.onExpiredReCaptcha = this.onExpiredReCaptcha.bind(this);
    this.captcha = null;
  }

  componentDidMount() {
    this.resetForm();
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
    document.getElementById("name").className = "form-control";
    document.getElementById("erreur_name").innerHTML = "";
    document.getElementById("error_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
    document.getElementById("email").className = "form-control";
    document.getElementById("erreur_email").innerHTML = "";
    document.getElementById("error_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;
  }
  handleChangeSubject(event) {
    this.setState({ subject: event.target.value });
    document.getElementById("subject").className = "form-control";
    document.getElementById("erreur_subject").innerHTML = "";
    document.getElementById("error_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;

  }
  handleChangeMessage(event) {
    this.setState({ message: event.target.value });
    document.getElementById("message").className = "form-control";
    document.getElementById("erreur_message").innerHTML = "";
    document.getElementById("error_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;

  }
  handleChangeCheckPolicy(event) {
    this.setState({ checkPolicy: event.target.checked });
    document.getElementById("erreur_checkPolicy").innerHTML = "";
    document.getElementById("error_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;
  }
  onChangeReCaptcha(value) {
    // on change captcha
    if (value != null) {
      // null means expired
      this.setState({ cValue: true });
      document.getElementById("error_contact").innerHTML = "";
      document.getElementById("error_contact").hidden = true;
      document.getElementById("erreur_captcha").innerHTML = "";
    }
  }
  onExpiredReCaptcha(value) {
    // When captcha is expired
    this.setState({ cValue: false });
  }

  handleSubmit(event) {
    event.preventDefault(); // on reste dans la même page, l'utilisateur est informé une fois le retour du serveur
    document.getElementById("submit").disabled = true;
    document.getElementById("message_contact").innerHTML = "";
    document.getElementById("message_contact").hidden = true;
    document.getElementById("error_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;
    const errors = this.validate(
      this.state.name.trim(),
      this.state.email.trim(),
      this.state.subject.trim(),
      this.state.message.trim(),
      this.state.checkPolicy
    );
    if (errors.length > 0) {
      document.getElementById("submit").disabled = false;
      document.getElementById("div_above_contact_form").scrollIntoView(false);
      return false;
    } else {
      if (!this.state.cValue) {
        document.getElementById("submit").disabled = false;
        document.getElementById("erreur_captcha").innerHTML = "Vous devez effectuer le test du Captcha.";
        document.getElementById("IdCaptcha").scrollIntoView(true);
        return false;
      }
    }

    const messageHtml = renderEmail(
      <MonEmail
        name={this.state.name}
        email={this.state.email}
        subject={this.state.subject}
      >
        {this.state.message}
      </MonEmail>
    );

    const messageUserHtml = renderEmail(
      <UserEmail
        name={this.state.name}
        email={this.state.email}
        subject={this.state.subject}
      >
        {this.state.message}
      </UserEmail>
    );

    axios({
      method: "POST",
      url:urls.NODE_SEND,
      //url:"/send/", // localhost
      data: {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        messageHtml: messageHtml,
        messageUserHtml: messageUserHtml
      }
    }).then(response => {
      if (response.data.msg === "success") {
        let saved_name = this.state.name;
        this.resetForm();
        document.getElementById("message_contact").innerHTML =
          "Merci, " +
          saved_name +
          ", de votre demande d’informations, nous ferons suite à cet échange dans les plus bref délais.";
          document.getElementById("message_contact").hidden = false;
      } else if (response.data.msg === "fail") {
        document.getElementById("error_contact").innerHTML =
          "L'envoi de l'email a échoué !";
          document.getElementById("error_contact").hidden = false;
      }
      document.getElementById("submit").disabled = false;
    });
    event.preventDefault();
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
      checkPolicy: false
    });
    document.getElementById("message_contact").hidden = true;
    document.getElementById("message_contact").innerHTML = "";
    document.getElementById("error_contact").hidden = true;
    document.getElementById("error_contact").innerHTML = "";
    this.captcha.reset();
    this.setState({ cValue: false });
  }

  validateEmail(email){
    let isValid = false;
    let regex = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,3})+$/i);
    if(email.length < 255){
      if(regex.test(email) === true){
        isValid =true
      }
    }
    return isValid;
  }

  validate(name, email, subject, message, checkPolicy) {
    const errors = [];
    const isValid = this.validateEmail(email);
    if (! checkPolicy) {
      errors.push("Veuillez cocher la case si vous souhaitez continuer.");
      document.getElementById("erreur_checkPolicy").innerHTML = "Veuillez cocher la case si vous souhaitez continuer.";
    }
    if (message.length < 3) {
      errors.push("Le message doit contenir au moins 3 caractères.");
      //document.getElementById("message").className = "form-control error-contact";
      document.getElementById("erreur_message").innerHTML = "Le message doit contenir au moins 3 caractères.";
    }
    if (subject.length < 2) {
      errors.push("Le sujet doit contenir au moins 2 caractères.");
      document.getElementById("erreur_subject").innerHTML = "Le sujet doit contenir au moins 2 caractères.";
    }
    if (isValid === false) {
      errors.push("L'email n'est pas valide.");
      document.getElementById("erreur_email").innerHTML = "L'email n'est pas valide.";
    } else {
      document.getElementById("email").className = "form-control";
    }
    if (name.length < 3) {
      errors.push("Le nom doit contenir au moins 3 caractères.");
      document.getElementById("erreur_name").innerHTML = "Le nom doit contenir au moins 3 caractères.";
    }
    return errors;
  }

  render() {
    const erreurValidationStyle = {
      color: 'red',
      fontSize: 12,
      height: 18,
      marginTop: -17
    };

    const erreurValidationCaptchaStyle = {
      color: 'red',
      fontSize: 12,
      height: 18,
      marginTop: 0
    };
    return (
        <div>
          <form action="" className="reveal-content contact-form " onChange={this.handleChangeForm}>
            <div id="message_contact" className="message-contact"></div>
            <div id="error_contact" className="error-contact"></div>
            <br/>
            <div className="form-group input-group">
            <span className="input-group-addon"><i className="fa fa-user-circle fa-color"></i></span>         
              <input
                onChange={this.handleChangeName}
                value={this.state.name}
                type="text"
                className="form-control"
                id="name"
                placeholder="Nom complet"
                required
              />
            </div>
            <div id="erreur_name" style={erreurValidationStyle}></div>
            <div className="form-group input-group">
            <span className="input-group-addon"><i className="fa fa-envelope fa-color"></i></span>
              <input
                onChange={this.handleChangeEmail}
                value={this.state.email}
                type="email"
                className="form-control"
                id="email"
                placeholder="vous@exemple.com"
                required
              />
            </div>
            <div id="erreur_email" style={erreurValidationStyle}></div>
            <div className="form-group input-group">
            <span className="input-group-addon"><i className="fa fa-header fa-color"></i></span>
              <input
                onChange={this.handleChangeSubject}
                value={this.state.subject}
                type="text"
                className="form-control"
                id="subject"
                placeholder="Sujet"
                required
              />
            </div>
            <div id="erreur_subject" style={erreurValidationStyle}></div>
            <div className="form-group input-group">
            <span className="input-group-addon"><i className="fa fa-pencil-square-o fa-color"></i></span>
              <textarea
                onChange={this.handleChangeMessage}
                value={this.state.message}
                className="form-control"
                rows="3"
                id="message"
                placeholder="Votre message"
                required
              ></textarea>
            </div>
            <div id="erreur_message" style={erreurValidationStyle}></div>
            <div className="form-group">
            <div className="form-group pos-left">
              <div className="text-left">
                <input
                  onChange={this.handleChangeCheckPolicy}
                  checked={this.state.checkPolicy}
                  type="checkbox"
                  className="form-group-lg"
                  id="checkPolicy"
                  required
                />
                <label htmlFor="checkPolicy" className="scale">
                  {" "}
                  En envoyant ce formulaire&#44; j&#39;accepte que les informations
                  saisies soient exploit&#233;es dans le cadre de la relation
                  commerciale qui peut en découler&#46;
                </label>
              </div>
            </div>
            <div id="erreur_checkPolicy" style={erreurValidationStyle}></div>
        
            <div id="IdCaptcha" className="float-left">
              <ReCAPTCHA className="captcha"
                ref={el => {
                  this.captcha = el;
                }}
                sitekey="6Lc2F8kUAAAAABhadBBspLUqvdbmT6SZUm1zCDWS"
                onChange={this.onChangeReCaptcha}
                onExpired={this.onExpiredReCaptcha}
                size="normal"
                badge="inline"
              />
              <div id="erreur_captcha" style={erreurValidationCaptchaStyle}></div>
            </div>      
            <div className="float-right">
              <button
                onClick={this.handleSubmit}
                id="submit"
                type="submit"
                className="btn-content btn-secondary btn-lg btn-sm"
                name="btnSend"
              >
              Envoyer
              </button>
            </div>
       
            </div>
            <div className="clear-both"></div>
          </form>
        </div>
    );
  }
}
